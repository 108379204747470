import React from "react";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { makeStyles } from "@mui/styles";

const ButtonWrapper = ({ children, buttonStyles, ...otherProps }) => {
  const useStyles = makeStyles(() => ({
    btn: {
      height: (otherStyles) => otherStyles.height,
      color: (otherStyles) => otherStyles.color,
      fontSize: (otherStyles) => otherStyles.fontSize,
      fontWeight: (otherStyles) => otherStyles.fontWeight,
      backgroundColor: (otherStyles) => otherStyles.backgroundColor,

      "&:hover": {
        backgroundColor: (otherStyles) => otherStyles.hoverColor,
      },
    },
  }));
  const { submitForm } = useFormikContext();
  const classes = useStyles(buttonStyles);

  const handleSubmit = () => {
    submitForm();
  };

  const configButton = {
    color: "primary",
    fullWidth: true,
    onSubmit: handleSubmit,
    ...otherProps,
  };

  return (
    <Button {...configButton} className={classes.btn}>
      {children}
    </Button>
  );
};

export default ButtonWrapper;
