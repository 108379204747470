import React, { useRef } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Grid, Button, Typography } from "@mui/material";
import Video from "../assets/videotos.mp4";
import Logo from "../assets/turistickaLogo.png";
import TextfieldWrapper from "../components/FormsUI/TextFieldWrapper";
import { axiosInstance } from "../utils/axios-instance";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100vh",
      width: "100vw",
      objectFit: "cover",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: -1,
      margin: 0,
      padding: 0,
    },
    centerForm: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    formContainer: {
      display: "flex",
      marginTop: 30,
      padding: "4rem 2rem",
      background: "rgba(255, 255, 255,0.97)",
      margin: "2rem",
      maxWidth: 450,
      height: "max-content",
      borderRadius: 10,
      justifyContent: "center",
    },
    Logo: {
      height: 70,
    },
    buttonSub: {
      width: "100%",
    },
  })
);

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const reRef = useRef();

  const INITIAL_FORM_STATE = {
    email: "",
    password: "",
  };
  const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string().email("Wrong email adress").required("Required field"),
    password: Yup.string()
      .required("Enter Password")
      .min(5, "Password must have at least 5 characters"),
  });

  const handleLogin = async (userInfo, { setErrors, resetForm }) => {
    try {
      const recaptchaToken = await reRef.current.executeAsync();
      reRef.current.reset();

      const res = await axiosInstance.post(`/api/user/login`, {
        email: userInfo.email,
        password: userInfo.password,
        token: recaptchaToken,
      });
      resetForm(INITIAL_FORM_STATE);
      const { token, user } = res.data;
      localStorage.setItem("token", token);
      setTimeout(() => {
        if (user.role === "admin") history.push("/tos");
        else history.push("/tos");
      }, 200);
    } catch (err) {
      const error = err.response.data.message.includes("User")
        ? "email"
        : "password";

      setErrors({
        [error]: err.response.data.message,
      });
    }
  };

  return (
    <div>
      <video className={classes.root} src={Video} autoPlay loop muted></video>
      <div className={classes.centerForm}>
        <Grid className={classes.formContainer}>
          <Grid item>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={handleLogin}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Grid container spacing={4} justifyContent="center">
                    <Grid item xs="10">
                      <Typography
                        variant="h6"
                        sx={{
                          color: "rgb(115, 112, 111)",
                          textAlign: "center",
                          mb: 2,
                        }}
                      >
                        Welcome to the media library of the National Tourism
                        Organisation of Serbia
                      </Typography>
                    </Grid>
                    <img src={Logo} className={classes.Logo} alt="logo" />

                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="email"
                        variant="outlined"
                        label="Email"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="password"
                        type="password"
                        variant="outlined"
                        label="Password"
                      />
                    </Grid>

                    <ReCAPTCHA
                      sitekey="6LdKtzYeAAAAAFfYLv62-szsk6df7d6RjJy3hsGG"
                      size="invisible"
                      ref={reRef}
                    />

                    <Grid item xs={12}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        className={classes.buttonSub}
                        sx={{ backgroundColor: "#4066B1" }}
                      >
                        Log in
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item style={{ padding: "10px 0", color: "#7e7c7b" }}>
                      <span>Don't have an account? </span>
                      <Link href="/register" style={{ color: "#7e7c7b" }}>
                        Register
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
