import React, { memo, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { axiosAuth } from "../utils/axios-instance";
import PublicRoutes from "./PublicRoutes";

const Auth = () => {
  const [userInfo, setUserInfo] = useState("");
  const { pathname } = useLocation();

  const isLoggedIn = async () => {
    try {
      const { data } = await axiosAuth("/api/user/my-profile");
      setUserInfo(data);
    } catch (error) {
      console.log(error, error.response);

      setUserInfo("");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      isLoggedIn();
    }
  }, []);

  return (
    <>
      {userInfo && pathname === "/" ? <Redirect to="/tos" /> : <PublicRoutes />}
    </>
  );
};

export default memo(Auth);
