import React from "react";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: "#4066b1",
    color: "white",
    borderRadius: 10,
    padding: "10px 25px",
    fontSize: 15,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    border: "none",
  },
}));

const AddButton = ({ click, label }) => {
  const classes = useStyles();
  return (
    <button className={classes.addButton} onClick={click}>
      <span>{label}</span> <AddIcon />{" "}
    </button>
  );
};

export default AddButton;
