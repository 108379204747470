/* eslint-disable */
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://medialibrary.serbia.travel/",
});

export const axiosAuth = axios.create({
  baseURL: "https://medialibrary.serbia.travel/",
});

axiosAuth.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
