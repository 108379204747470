import * as React from "react";
import { useDebounce } from "use-debounce";
import Box from "@mui/material/Box";
import ImageCard from "../components/cards/ImageCard";
import FloatingButton from "../components/FormsUI/FloatingButton";
import { axiosAuth as axios } from "../utils/axios-instance";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useSWR from "swr";
import { Grid, useMediaQuery } from "@mui/material";
import FilterSideBar from "../components/filterSideBar/FilterSideBar";
import PageTitle from "../components/FormsUI/PageTitle";
import { objectToUrlString } from "../utils/filterUtills";
// import { filterEmptyData } from "../utils/filterEmptyData";
import FloatingButtonDownload from "../components/FormsUI/FloatingDownloadZip";
import NoImg from "../assets/noimg.png";
import CreateLinkModal from "../components/Modal/CreateLinkModal";
import SearchBar from "../components/SearchBar/SearchBar";
import ResultNotFound from "../components/FormsUI/ResultNotFound";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@emotion/react";

export default function CustomImageList() {
  //list of ids to share
  const [list, setList] = React.useState([]);
  // image list
  const [allImages, setAllImages] = React.useState([]);
  const [totalResults, setTotalResults] = React.useState("");
  //filters list for rendering on sidebar
  const [filters, setFilters] = React.useState({});
  const [load, setLoad] = React.useState(false);
  const [shareLink, setShareLink] = React.useState("");
  const [page, setPage] = React.useState(1);
  // eslint-disable-next-line
  const [imagePerPage, setImagePerPage] = React.useState(19);
  const [zipList, setZipList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1000));
  const matches500 = useMediaQuery(theme.breakpoints.down(500));
  const [searchDebounced] = useDebounce(search, 1500);
  // Share link modal
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  // selected images []
  const handleSelected = (id, name) => {
    if (!list.includes(id)) {
      setList([...list, id]);
    } else {
      setList(list.filter((sel) => sel !== id));
    }
    if (!zipList.includes(name)) {
      setZipList([...zipList, name]);
    } else {
      setZipList(zipList.filter((sel) => sel !== name));
    }
  };
  const { data } = useSWR("/api/filter?skip=0&limit=10000");

  const handleShare = (string) => {
    setShareLink(string);
  };
  // console.log(data, error, isValidating);
  // console.log("SHARE LINK", shareLink);
  // console.log("zip list", zipList);

  // console.log(Object.keys(filters) > 0 ? Object.keys : null)nst

  // const filterica = Object.keys(filters)
  //   .filter((key) => filters[key].length != 0)
  //   .reduce((acc, key) => {
  //     acc[key] = filters[key];
  //     return acc;
  //   }, {});
  // console.log("FILTERICA", filterica);
  // `/api/search/filter-search?page=${page+1}&limit=${imagePerPage}&${urlstring}`
  const getAllImages = async () => {
    const urlstring = objectToUrlString(filters);
    const searchString = urlstring
      ? `&search=${searchDebounced}`
      : `search=${searchDebounced}`;

    try {
      setLoad(true);
      const { data } = await axios.get(
        `/api/image?skip=${
          page - 1
        }&limit=${imagePerPage}&${urlstring}${searchString}`
      );
      setAllImages(data.images);
      setTotalResults(data.totalResults);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAllImages();
    // eslint-disable-next-line
  }, [filters, searchDebounced, page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const LoaderRender = () => {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  };

  // console.log("list of ids to share", list);
  // console.log(allImages);
  // console.log("Filters", filters);
  return (
    <Grid container>
      {data && matches && (
        <FilterSideBar data={data} setFilters={setFilters} filters={filters} />
      )}
      <Grid item xs={12} sm={9} md={10} lg={10}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageTitle>Media Gallery</PageTitle>
          <Box
            sx={{
              mb: 4,
              mt: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              flexWrap: matches500 ? "wrap" : "nowrap",
            }}
          >
            {data && !matches && (
              <FilterSideBar
                data={data}
                setFilters={setFilters}
                filters={filters}
              />
            )}
            <SearchBar search={search} setSearch={setSearch} />
          </Box>
          {load ? LoaderRender() : null}
          {allImages.length === 0 && Object.keys(filters).length > 0 ? (
            <ResultNotFound />
          ) : null}
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
          >
            {allImages &&
              allImages?.map((item) => (
                <Box sx={{ p: 1 }} key={item._id}>
                  <ImageCard
                    imgUrl={item.imgUrl || NoImg}
                    author={item.author}
                    click={() => handleSelected(item._id, item.imageName)}
                    id={item._id}
                    thumbUrl={item?.thumbUrl || NoImg}
                    title={item.title}
                    mymeType={item.mimeType}
                    imageName={item.imageName}
                    selectIcon={true}
                  />
                </Box>
              ))}
          </Box>
          <Stack spacing={2}>
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Pagination
                count={
                  totalResults > imagePerPage
                    ? Math.ceil(totalResults / imagePerPage)
                    : 1
                }
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Stack>
          <FloatingButton
            listOfImages={list}
            addShare={handleShare}
            open={handleModalOpen}
          />
          <FloatingButtonDownload imageList={zipList} />
        </Box>
        <CreateLinkModal
          linkString={shareLink}
          openModal={handleModalOpen}
          close={handleModalClose}
          isOpen={open}
        />
      </Grid>
    </Grid>
  );
}
