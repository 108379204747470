import React, { useState } from "react";
import {
  Collapse,
  Grid,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import InputFactory from "../inputFactory/InputFactory";

const FilterGroup = ({
  setReset,
  setFilters,
  reset,
  groups,
  wrapper,
  isGrouped,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Grid container>
      <Paper
        elevation={0}
        sx={{
          width: "90%",
          // margin: isLast ? "5px auto 20px auto" : "5px auto",
          margin: "5px auto",
          minWidth: 150,
        }}
      >
        <ListItemButton onClick={handleOpen} sx={{ width: "100%" }}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "#686868",
            }}
          >
            {wrapper}
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout={200} unmountOnExit>
          <>
            {groups[wrapper].map((el, index) => (
              <InputFactory
                data={el}
                key={index}
                setFilters={setFilters}
                setReset={setReset}
                reset={reset}
                isGrouped={isGrouped}
              />
            ))}
          </>
        </Collapse>
      </Paper>
    </Grid>
  );
};

export default FilterGroup;
