import React from "react";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import Fab from "@mui/material/Fab";
import { axiosAuth as axios } from "../../utils/axios-instance";
import { Box } from "@mui/material";

const FloatingButton = ({ listOfImages, addShare, open }) => {
  const handleClick = () => {
    createShareLink();
    open();
  };

  const createShareLink = async () => {
    try {
      const { data } = await axios.post("api/share", {
        images: listOfImages,
      });
      addShare(data);
    } catch (err) {
      console.log(err);
    }
  };
  // {
  //   url: "/api/share",
  //   method: "post",
  //   images: listOfImages,
  // }
  return (
    <Fab
      variant="extended"
      sx={{
        backgroundColor: "#ADD8E6",
        position: "fixed",
        bottom: 20,
        right: 20,
        display: !listOfImages.length ? "none" : "block",
        alignItems: "center",
      }}
      onClick={handleClick}
      // disabled={!listOfImages.length ?? true}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <AddLinkOutlinedIcon sx={{ mr: 1 }} />
        create link
      </Box>
    </Fab>
  );
};

export default FloatingButton;
