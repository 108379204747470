import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import { Button, Container, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ButtonWrapper from "../components/FormsUI/ButtonWrapper";
import TextfieldWrapper from "../components/FormsUI/TextFieldWrapper";
import CheckboxWrapper from "../components/FormsUI/CheckboxWrapper";
import { axiosAuth as axios } from "../utils/axios-instance";
import PageTitle from "../components/FormsUI/PageTitle";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    maxWidth: 500,
    margin: "0 auto",
  },
  gridAlign: {
    // marginTop: theme.spacing(2),
    alignItems: "flex-start",
    justifyContent: "center",
  },
  backToButton: {
    margin: 8,
    padding: 9,
  },
}));

const Register = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "default",
  });
  const [severity, setSeverity] = useState("info");
  const classes = useStyles();
  const history = useHistory();
  const reRef = useRef();

  // const ctx = React.useContext(AppContext)
  // ctx.handleAuthHeader(true)

  const INITIAL_FORM_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    company: "",
    country: "",
    phone: "",
    terms: false,
  };

  const FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email adress")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Password confirm is required"),
    company: Yup.string().required("Company is required"),
    country: Yup.string().required("Country is required"),
    phone: Yup.string().required("Phone number is required"),
    terms: Yup.bool().oneOf([true], "You must accept the terms"),
  });

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const handleRegisterSubmit = async (userInfo, { setErrors, resetForm }) => {
    try {
      const token = await reRef.current.executeAsync();
      reRef.current.reset();

      await axios.post(`/api/user/register`, {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        password: userInfo.password,
        company: userInfo.company,
        country: userInfo.country,
        phone: userInfo.phone,
        token,
      });
      resetForm(INITIAL_FORM_STATE);
      setSeverity("success");
      setSnackbar({
        open: true,
        message: "Registration successful",
      });
      setTimeout(() => {
        history.push("/");
      }, 4000);
    } catch (err) {
      console.log(err);
      let error = "email";
      if (err.response.data.errors) {
        error = err.response.data.errors[0].includes("Password") && "password";
      }
      if (err.response.data.errors) {
        error = err.response.data.errors[0].includes("Name") && "firstName";
      }
      setErrors({
        [error]: error.response,
      });
      setSeverity("warning");
      setSnackbar({
        open: true,
        message: "Registration unsuccessful",
      });
    }
  };

  return (
    <Grid container className={classes.gridAlign}>
      <Grid container className={classes.backToButton}>
        <Button
          variant="contained"
          color="error"
          onClick={() => history.goBack()}
        >
          Back to login page
        </Button>
      </Grid>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <PageTitle>REGISTER</PageTitle>
        <Container maxWidth="xs">
          <div className={classes.formWrapper}>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={handleRegisterSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="firstName"
                        label="First name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="lastName"
                        label="Last name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="email"
                        label="Email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="confirmPassword"
                        label="Confirm password"
                        type="password"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="company"
                        label="Company"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="country"
                        label="Country"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextfieldWrapper
                        name="phone"
                        label="Phone number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography variant="p" style={{ fontSize: 12 }}>
                        I hereby undertake to use the photographs I obtain from
                        the Archives of the National Tourist Organisation of
                        Serbia (NTOS) in their integral form for the purposes of
                        promoting tourism of the Republic of Serbia, not to use
                        for commercial purposes or give to third parties. Along
                        with each photo, I will state the name of the author, if
                        given, with the obligatory note that the photo belongs
                        to the NTOS Archive.
                      </Typography>
                      <CheckboxWrapper
                        name="terms"
                        label="I accept the terms of use*"
                        currentValue={values.terms}
                        required
                      />
                    </Grid>

                    <ReCAPTCHA
                      sitekey="6LdKtzYeAAAAAFfYLv62-szsk6df7d6RjJy3hsGG"
                      size="invisible"
                      ref={reRef}
                    />

                    <Grid item xs={12}>
                      <ButtonWrapper
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        height={48}
                        fontSize="1rem"
                      >
                        Register
                      </ButtonWrapper>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Register;
