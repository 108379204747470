import React from "react";
import CheckBoxContainer from "./CheckBoxContainer";
import SelectContainer from "./SelectContainer";

const InputFactory = ({
  data,
  setFilters,
  setReset,
  reset,
  isGrouped,
  // totalLength,
  // currentIndex,
}) => {
  // const isLast = currentIndex + 1 === totalLength;
  return (
    <>
      {data.type === "checkbox" && (
        <CheckBoxContainer
          data={data}
          setFilters={setFilters}
          setReset={setReset}
          reset={reset}
          isGrouped={isGrouped}
        />
      )}
      {data.type === "select" && (
        <SelectContainer
          data={data}
          setFilters={setFilters}
          setReset={setReset}
          reset={reset}
          isGrouped={isGrouped}
        />
      )}
    </>
  );
};

export default InputFactory;
