export const objectToUrlString = (obj) => {
  let stringy = [];
  for (const element in obj) {
    let urlString = Array.isArray(obj[element])
      ? obj[element].map((item) => `${element.replaceAll(" ", "_")}=${item}`)
      : obj[element] !== "" && obj[element] !== null
      ? [`${element}=${obj[element]}`]
      : [];
    stringy = [...stringy, ...urlString];
  }
  return stringy.join("&");
};

// console.log("Object to string",objectToUrlString(filters))
