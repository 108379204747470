import * as Yup from "yup";
export const FORM_VALIDATION = Yup.object().shape({
    // oldpassword: Yup.string()
    //   .required("Unesite lozinku")
    //   .min(5, "lozinka mora imati minimum 5 karaktera"),
    password: Yup.string()
      .required("Enter your password")
      .min(5, "Password must have at least 5 characters"),
    repeatpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("You must confirm your password"),
  });


  export const USER_FORM_VALIDATION = Yup.object().shape({
    oldpassword: Yup.string()
        .required("Enter your password")
        .min(5, "Password must have at least 5 characters"),
    password: Yup.string()
      .required("Enter your new password")
      .min(5, "Password must have at least 5 characters"),
    repeatpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("You must confirm your password"),
  });

//   const INITIAL_FORM_STATE = {
//     // oldpassword: "",
//     password: "",
//     repeatpassword: "",
//   };

//   const INITIAL_FORM_STATE_USER = {
//     password,
//     newpassword: "",
//     newpassword2: "",
// //   };
