import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import notFoundGif from "../assets/NotFound.gif";

import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  return (
    <Grid xs={12} container justifyContent="center">
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h2" sx={{ marginTop: "3rem" }}>
          Oops... Looks like you're lost!
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <img src={notFoundGif} alt="Page not found..." />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => history.push("/tos")}
        >
          back to home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
