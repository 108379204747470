import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import { axiosAuth as axios } from "../../utils/axios-instance";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";

import SimpleModal from "../../components/Modal/Modal";
import AddButton from "../../components/FormsUI/AddButton";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import TabPanel from "../../components/TabPanel/TabPanel";
import PageTitle from "../../components/FormsUI/PageTitle";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  deleteButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  tableDiv: {
    margin: "20px 20px 50px 20px",
  },
  title: {
    color: "#6a6767",
  },
  icon: {
    color: "#6a6767",
    marginLeft: 10,
  },
}));

const Users = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalDeleted, setTotalDeleted] = useState(0);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [value, setValue] = React.useState(0);
  const [load, setLoad] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUsers = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `/api/admin/users?skip=${page - 1}&limit=${rowsPerPage}`
      );
      setUsers(response.data.users);
      setTotal(response.data.totalResults);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  const getDeletedUsers = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `/api/admin/users?skip=${page - 1}&deleted=yes&limit=${rowsPerPage}`
      );
      setDeletedUsers(response.data.users);
      setTotalDeleted(response.data.totalResults);
      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  };

  const backToActive = async (data) => {
    try {
      setRefresh(true);
      await axios.patch(`/api/admin/users/user/${data._id}`);
      setSeverityHandler("success");
      setSnackbarHandler({
        open: true,
        message: "Status successfully changed.",
      });
      setRefresh(false);
    } catch (error) {
      console.log(error);
      setSeverity("warning");
      setSnackbar({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "Status change failed.",
      });
    }
  };

  const actionBodyTemplate = (rowData) => (
    <Grid key="edit" container spacing={2}>
      <Grid item xs={6}>
        <Button
          name="izmeni"
          className={classes.editButton}
          onClick={() => editUser(rowData)}
        >
          <EditIcon className={classes.icon} />
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          name="obrisi"
          className={classes.deleteButton}
          onClick={() => deleteUser(rowData)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </Grid>
    </Grid>
  );

  const deletedActionBodyTemplate = (rowData) => (
    <Grid key="edit" container spacing={2}>
      <Grid item xs={12}>
        <Tooltip title="Change to active user">
          <Button
            name="izmeni"
            className={classes.editButton}
            onClick={() => backToActive(rowData)}
          >
            <UndoIcon className={classes.icon} />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      field: (user) => {
        return `${user.firstName ? user.firstName : "Unknown"} ${
          user.lastName ? user.lastName : "Unknown"
        }`;
      },
      header: "NAME",
    },
    { field: "email", header: "EMAIL" },
    {
      field: (user) => {
        return `${
          user.role === "admin"
            ? "Admin"
            : user.role === "creator"
            ? "Creator"
            : user.role === "user"
            ? "User"
            : ""
        }`;
      },
      header: "ROLE",
    },
    { field: "edit", body: actionBodyTemplate, width: 200 },
  ];

  const deletedColumns = [
    {
      field: (user) => {
        return `${user.firstName ? user.firstName : "Unknown"} ${
          user.lastName ? user.lastName : "Unknown"
        }`;
      },
      header: "NAME",
    },
    { field: "email", header: "EMAIL" },
    {
      field: (user) => {
        return `${
          user.role === "admin"
            ? "Admin"
            : user.role === "creator"
            ? "Creator"
            : user.role === "user"
            ? "User"
            : ""
        }`;
      },
      header: "ROLE",
    },
    { field: "edit", body: deletedActionBodyTemplate, width: 200 },
  ];

  useEffect(() => {
    if (!refreshState) {
      getDeletedUsers();
      getUsers();
    }
    // eslint-disable-next-line
  }, [refreshState, page]);

  useEffect(() => {
    if (value === 0) getUsers();
    if (value === 1) getDeletedUsers();
    // eslint-disable-next-line
  }, [value]);

  const setRefresh = (state) => {
    setRefreshState(state);
  };

  const setSeverityHandler = (state) => {
    setSeverity(state);
  };

  const setSnackbarHandler = (state) => {
    setSnackbar(state);
  };

  const addUser = () => {
    setAddOpen(true);
  };

  const editUser = (data) => {
    setEditOpen(true);
    setEditUserData(data);
  };

  const deleteUser = (data) => {
    setDeleteOpen(true);
    setDeleteUserData(data);
  };

  const setPageHandler = (x) => {
    setPage(x);
  };

  return (
    <div className={classes.tableDiv}>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {addOpen && (
        <CreateUser
          modalOpen={addOpen}
          setModal={setAddOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}

      {editOpen && editUserData && (
        <EditUser
          data={editUserData}
          modalOpen={editOpen}
          setModal={setEditOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}

      {deleteOpen && deleteUserData && (
        <DeleteUser
          data={deleteUserData}
          setPage={setPage}
          modalOpen={deleteOpen}
          setModal={setDeleteOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}

      <PageTitle>User Management</PageTitle>
      <AddButton label="ADD USER" click={addUser} />

      <Tabs value={value} onChange={handleChange} aria-label="user tabs">
        <Tab label="Active users" disableRipple />
        <Tab label="Deleted users" disableRipple />
      </Tabs>

      <div>
        {load ? (
          <SimpleModal />
        ) : (
          <>
            <TabPanel value={value} index={0}>
              <Table
                value={users}
                emptyMessage="No users"
                columns={columns}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPageHandler}
                total={total}
                withPagination={true}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Table
                value={deletedUsers}
                emptyMessage="No deleted users"
                columns={deletedColumns}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPageHandler}
                total={totalDeleted}
                withPagination={true}
              />
            </TabPanel>
          </>
        )}
      </div>
    </div>
  );
};

export default Users;
