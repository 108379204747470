import React, { useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Fab from "@mui/material/Fab";
import { axiosAuth as axios } from "../../utils/axios-instance";
import fileDownload from "js-file-download";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';

const FloatingButtonDownload = ({ imageList }) => {
  // const slike = ["hqdefault.jpg", "Screenshot-from-2021-07-07-10-30-52.png"];
  const [progress, setProgress] = useState(false);
  console.log(progress);

  const downloadZipHandler = async () => {
    const val = Math.floor(1000 + Math.random() * 9000);
    setProgress(true);
    const { data } = await axios.post(
      "/api/image/zip/from/gallery",
      { images: imageList },
      {
        responseType: "arraybuffer",
      }
    );
    setProgress(false);

    return fileDownload(data, `tosDownload-${val}.zip`);
  };

  return (
    <Fab
      variant="extended"
      sx={{
        backgroundColor: "#ADD8E6",
        position: "fixed",
        bottom: 80,
        right: 20,
        display: !imageList.length ? "none" : "block",
      }}
      disabled={progress ? true : false}
      onClick={downloadZipHandler}
      
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {!progress ? <FileDownloadOutlinedIcon
          sx={{ mr: 1, color: "rgba(0, 0, 0, 0.87)" }}
        /> :
        <CircularProgress size={25} sx={{ mr: 1, color: "rgba(0, 0, 0, 0.87)" }}/>}
        DOWNLOAD ZIP
      </Box>
    </Fab>
  );
};

export default FloatingButtonDownload;
