import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableLinks from "../components/TableLinks";
// {currentUser && <UserSettingsForm userProfile={currentUser} updateUser={updateUser} />}
import PersonalSettingsIllustration from "../assets/userprofile.png";
import UserProfileForm from "../components/FormsUI/MyprofileForm/UserProfileForm";
// import { useSelector } from "react-redux";
import { axiosAuth as axios } from "../utils/axios-instance";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MyProfile = () => {
  // const myInfo = useSelector((state) => state.myProfile.user);
  const [links, setLinks] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const getLinks = async () => {
    const res = await axios.get("/api/share/");
    let linksArr = [...res.data];
    let arr = [];
    // eslint-disable-next-line
    linksArr.map((link) => {
      arr.push({
        id: link._id,
        date: link.dateCreated,
        url: link.url,
      });
    });
    setLinks(arr);
  };

  useEffect(() => {
    if (!refresh) getLinks();
  }, [refresh]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <Paper
          sx={{
            maxWidth: 800,
            display: "flex",
            overflow: "auto",
            flexDirection: "column",
            padding: 2,
            margin: "0 auto",
          }}
        >
          <Typography component="h2" variant="h6" color="#4066B1" gutterBottom>
            User Settings
          </Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={5}>
              <img
                src={PersonalSettingsIllustration}
                width="90%"
                alt="person-illustration"
              />
            </Grid>
            <Grid item xs={7}>
              <UserProfileForm />
            </Grid>
          </Grid>
        </Paper>
        <Box
          sx={{
            maxWidth: 900,
            width: "100%",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            padding: 1,
            margin: "10px auto",
          }}
        >
          <Accordion sx={{ mt: 0 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="#4066B1">My Links</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableLinks
                data={links}
                setSeverity={setSeverity}
                setRefresh={setRefresh}
                setSnackbar={setSnackbar}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </div>
  );
};

export default MyProfile;
