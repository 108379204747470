import roles from "./roles";
import CustomImageList from "../pages/HomePage";
import Users from "../pages/Users/Users";
import Filters from "../pages/Filters/Filters";
import MyProfile from "../pages/MyProfile";
import Images from "../pages/Images/Images";
import Links from "../pages/Links/Links";

const privateRoutesConfig = [
  {
    component: CustomImageList,
    path: "/",
    title: "Home Page",
    permission: [roles.ADMIN, roles.CREATOR, roles.USER],
  },
  {
    component: Users,
    path: "/users",
    title: "users",
    permission: [roles.ADMIN],
  },
  {
    component: Links,
    path: "/links",
    title: "Links",
    permission: [roles.ADMIN],
  },
  {
    component: MyProfile,
    path: "/my-profile",
    title: "My profile",
    permission: [roles.ADMIN, roles.CREATOR, roles.USER],
  },

  {
    component: Filters,
    path: "/filters",
    title: "Filters",
    permission: [roles.ADMIN, roles.CREATOR, roles.USER],
  },
  {
    component: Images,
    path: "/images",
    title: "Images",
    permission: [roles.ADMIN, roles.CREATOR, roles.USER],
  },
];

export default privateRoutesConfig;
