import React, { useEffect, useState } from "react";
import {
  // Redirect,
  useHistory,
  useLocation,
  // useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
// import useSWR from 'swr'
import privateRoutesConfig from "./privateRoutesConfig";
import MapAllowedRoutes from "./MapAllowedRoutes";
import { axiosAuth } from "../utils/axios-instance";
import { myProfile } from "../redux/actions/userActions";
import { getAllowedRoutes } from "../utils/getAllowedRoutes";

const PrivateRoutes = () => {
  // eslint-disable-next-line

  // const match = useRouteMatch('/app')
  const dispatch = useDispatch();
  const [allowedRoutes, setAllowedRoutes] = useState("");
  const history = useHistory();
  const location = useLocation();

  const isLoggedIn = async () => {
    try {
      const { data } = await axiosAuth("/api/user/my-profile");

      const role = data.user.role.toUpperCase();

      dispatch(myProfile(data));

      // if (role === "ADMIN" && location.pathname === "/tos")
      //   history.push("/tos/korisnici");
      // console.log(data, rola, 'test from private')
      // if (rola === 'GOST' && location.pathname === '/app') {
      //     useSWR(`/api/user/my-profile`, {
      //         refreshInterval: 3000,
      //     })
      // }

      if (data) {
        setAllowedRoutes(getAllowedRoutes(privateRoutesConfig, role));
      }
    } catch (error) {
      setAllowedRoutes("");
      console.log(error, error.response);
      localStorage.removeItem("token");
      history.push("/");
    }

    // return !!localStorage.getItem('roles')
  };

  useEffect(() => {
    // if (location.pathname.includes('/app')) {
    isLoggedIn();
    // }
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      {allowedRoutes && (
        <MapAllowedRoutes
          routes={allowedRoutes}
          basePath="/tos"
          isAddNotFound
        />
      )}
    </>
  );
};

export default PrivateRoutes;
