import React, { useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const CheckboxWrapper = ({ name, label, currentValue, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { checked } = evt.target;

    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange,
    ...otherProps,
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    // configFormControl.helperText = meta.error;
  }

  useEffect(() => {
    if (currentValue !== undefined) setFieldValue(name, currentValue);
  }, [name, currentValue, setFieldValue]);

  return (
    <FormControl {...configFormControl}>
      <FormGroup>
        <FormControlLabel
          style={{ padding: 10 }}
          control={
            <Checkbox
              checked={currentValue}
              {...configCheckbox}
              color="primary"
            />
          }
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

// CheckboxWrapper.propTypes = {
//   name: PropTypes.string,
//   label: PropTypes.string,
//   currentValue: PropTypes.bool.isRequired,
// };

// CheckboxWrapper.defaultProps = {
//   name: "",
//   label: "",
//   // currentValue: false,
// };

export default CheckboxWrapper;
