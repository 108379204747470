import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/turistickaLogo.png";
import { styled } from "@mui/system";
import {
  Button,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

// const DivLogo = styled("div")({
//   display: "flex",
//   marginLeft: "1rem",
// });

const DivLogo = styled("div")(({ theme }) => ({
  display: "flex",
  marginLeft: "4rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "1rem",
  },
}));

const DivLogoutWrapper = styled("div")(({ theme }) => ({
  marginRight: "4rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    marginRight: "1rem",
  },
}));

const ListItemTextStyled = styled(
  ListItemText,
  {}
)(({ theme }) => ({
  color: "white",
  opacity: 0.7,
  
}));

const ImageStyled = styled("img")({
  width: 100,
  marginRight: "0.5rem",
});
const ToolbarMargin = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  // marginBottom: "3em",
  [theme.breakpoints.down("md")]: {
    marginBottom: "2em",
  },
  [theme.breakpoints.down("xs")]: {
    marginBottom: "1.25em",
  },
}));
const TabStyled = styled(
  Tab,
  {}
)({
  fontSize: "0.8rem",
  fontWeight: 700,
  "&.Mui-selected": {
    color: "#99001C",
  },
});
const Navbar = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1500));
  const matches450 = useMediaQuery(theme.breakpoints.down(450));

  const [openDrawer, setOpenDrawer] = useState(false);
  //get profile from redux
  const myProfile = useSelector((state) => state.myProfile);

  const [selectedTab, setSelectedTab] = useState(0);
  //handle tab selection on refresh to track current route to highlight it
  useEffect(() => {
    if (pathname === "/tos" && selectedTab !== 0) setSelectedTab(0);
    if (pathname === "/tos/images" && selectedTab !== 1) setSelectedTab(1);
    if (pathname === "/tos/users" && selectedTab !== 2) setSelectedTab(2);
    if (pathname === "/tos/links" && selectedTab !== 3) setSelectedTab(3);
    if (pathname === "/tos/filters" && selectedTab !== 4) setSelectedTab(4);
    if (pathname === "/tos/my-profile" && selectedTab !== 5) setSelectedTab(5);
  }, [pathname, selectedTab]);

  // Check if user is unnamed, and if so show email
  const getName = (profile) => {
    if (profile?.firstName && profile?.lastName)
      return `${profile.firstName} ${profile.lastName}`;
    if (profile?.firstName && !profile?.lastName)
      return `${profile.firstName} `;
    if (!profile?.firstName && profile?.lastName) return `${profile.lastName} `;
    if (!profile?.firstName && !profile?.lastName) return `${profile.email} `;
  };
  //handle tab change in navbar
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  const tabs = (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      sx={{
        margin: "auto",
      }}
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
    >
      <TabStyled label="Gallery" component={Link} to="/tos" disableRipple />
      (
      <TabStyled
        label="Images"
        component={Link}
        to="/tos/images"
        disableRipple
        sx={{
          display:
            myProfile.user.role && myProfile.user.role !== "user"
              ? "inline-flex"
              : "none",
        }}
      />
      )
      <TabStyled
        label="users"
        component={Link}
        to="/tos/users"
        disableRipple
        sx={{
          display: myProfile.user.role === "admin" ? "inline-flex" : "none",
        }}
      />
      <TabStyled
        label="links"
        component={Link}
        to="/tos/links"
        disableRipple
        sx={{
          display: myProfile.user.role === "admin" ? "inline-flex" : "none",
        }}
      />
      <TabStyled
        label="filters"
        component={Link}
        to="/tos/filters"
        disableRipple
        sx={{
          display: myProfile.user.role === "admin" ? "inline-flex" : "none",
        }}
      />
      <TabStyled
        label="my profile"
        component={Link}
        to="/tos/my-profile"
        disableRipple
      />
    </Tabs>
  );

  const drawer = (
    <>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            backgroundColor: "#99001A",
            // color: "white",
            minWidth: "200px",
          },
        }}
        sx={{ minWidth: 200 }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <List
          disablePadding
          sx={{ ".Mui-selected": { backgroundColor: "#99001C" } }}
        >
          <ListItemButton
            onClick={() => {
              setOpenDrawer(false);
              setSelectedTab(0);
            }}
            selected={selectedTab === 0}
            divider
            component={Link}
            to="/tos"
            disableRipple
          >
            <ListItemTextStyled
              sx={{
                opacity: selectedTab === 0 ? 1 : 0.7,
                fontWeight: 900,
              }}
            >
              GALLERY
            </ListItemTextStyled>
          </ListItemButton>

          <ListItemButton
            // onClick={() => setOpenDrawer(false)}
            divider
            onClick={() => {
              setOpenDrawer(false);
              setSelectedTab(1);
            }}
            selected={selectedTab === 1}
            component={Link}
            to="/tos/images"
            disableRipple
            sx={{
              display: myProfile.user.role === "admin" ? "inline-flex" : "none",
              width: "100%",
            }}
          >
            <ListItemTextStyled
              xs={{ opacity: selectedTab === 1 ? 1 : 0.7, fontWeight: 900 }}
            >
              IMAGE SETTINGS
            </ListItemTextStyled>
          </ListItemButton>

          <ListItemButton
            // onClick={() => setOpenDrawer(false)}
            divider
            onClick={() => {
              setOpenDrawer(false);
              setSelectedTab(2);
            }}
            selected={selectedTab === 2}
            component={Link}
            to="/tos/users"
            disableRipple
            sx={{
              display: myProfile.user.role === "admin" ? "inline-flex" : "none",
              width: "100%",
            }}
          >
            <ListItemTextStyled
              xs={{ opacity: selectedTab === 2 ? 1 : 0.7, fontWeight: 900 }}
            >
              USERS
            </ListItemTextStyled>
          </ListItemButton>

          <ListItemButton
            // onClick={() => setOpenDrawer(false)}
            divider
            onClick={() => {
              setOpenDrawer(false);
              setSelectedTab(3);
            }}
            selected={selectedTab === 3}
            sx={{
              display: myProfile.user.role === "admin" ? "inline-flex" : "none",
              width: "100%",
            }}
            component={Link}
            to="/tos/links"
            disableRipple
          >
            <ListItemTextStyled
              xs={{ opacity: selectedTab === 3 ? 1 : 0.5, fontWeight: 900 }}
            >
              LINKS
            </ListItemTextStyled>
          </ListItemButton>

          <ListItemButton
            // onClick={() => setOpenDrawer(false)}
            divider
            onClick={() => {
              setOpenDrawer(false);
              setSelectedTab(4);
            }}
            selected={selectedTab === 4}
            component={Link}
            to="/tos/filters"
            disableRipple
            sx={{
              display: myProfile.user.role === "admin" ? "inline-flex" : "none",
              width: "100%",
            }}
          >
            <ListItemTextStyled
              xs={{ opacity: selectedTab === 4 ? 1 : 0.7, fontWeight: 900 }}
            >
              FILTERS
            </ListItemTextStyled>
          </ListItemButton>

          <ListItemButton
            // onClick={() => setOpenDrawer(false)}
            divider
            onClick={() => {
              setOpenDrawer(false);
              setSelectedTab(5);
            }}
            selected={selectedTab === 5}
            component={Link}
            to="/tos/my-profile"
            disableRipple
          >
            <ListItemTextStyled
              xs={{
                color: "white",
                opacity: selectedTab === 5 ? 1 : 0.7,
              }}
            >
              MY PROFILE
            </ListItemTextStyled>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              setOpenDrawer(false);
              handleLogout();
            }}
            disableRipple
            component={Button}
            color="secondary"
            variant="contained"
            sx={{
              display: myProfile.user.role === "admin" ? "inline-flex" : "none",
              width: "100%",
            }}
          >
            <ListItemTextStyled
              xs={{
                opacity: selectedTab === 6 ? 1 : 0.7,
                fontWeight: 900,
              }}
            >
              LOGOUT
            </ListItemTextStyled>
          </ListItemButton>
        </List>
      </SwipeableDrawer>
      <IconButton
        sx={{
          marginLeft: "auto",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon sx={{ height: "50px", width: "50px" }} />
      </IconButton>
    </>
  );
  return (
    <>
      <AppBar
        // xs={clsx(classes.appBar, classes.header)}
        position="fixed"
        elevation={1}
        sx={{
          background: "#ffff",
          color: "#797676",
          display: myProfile?.user?.role ? "block" : "none",
        }}
      >
        <Toolbar>
          <Grid
            container
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Grid item lg={2} md={2} sm={2} order={1}>
              <DivLogo>
                <Link to="/tos">
                  <ImageStyled src={Logo} alt="Logo" />
                </Link>
              </DivLogo>
            </Grid>
            <Grid
              item
              order={matches ? 3 : 2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: matches ? "flex-end" : "center",
              }}
            >
              {matches ? drawer : tabs}
            </Grid>
            {!matches450 && (
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
                order={matches ? 2 : 3}
              >
                <DivLogoutWrapper>
                  <Hidden smDown>
                    <Typography
                      variant="p"
                      sx={{
                        color: "#797676",
                        marginRight: (theme) =>
                          theme.breakpoints.down("lg") ? 5 : 1,
                      }}
                    >
                      {`Welcome, ${getName(myProfile?.user)} ${getName(myProfile?.user) == 'Admin TOS'? '| Maintenance activated until: 24.02.2025.' : ''}`}
                    </Typography>
                  </Hidden>

                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontWeight: 700,
                      minWidth: 100,
                      fontSize: "0.8rem",
                      padding: 0,
                    }}
                    onClick={handleLogout}
                  >
                    Log out
                  </Button>
                </DivLogoutWrapper>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      <ToolbarMargin />
    </>
  );
};

export default Navbar;
