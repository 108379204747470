import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { myProfileReducer } from "./redux/reducers/userREducers";
import { filtersReducer } from "./redux/reducers/filterReducers";

const reducer = combineReducers({
  myProfile: myProfileReducer,
  filter: filtersReducer,
});


const initialState = {};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
