import React from "react";
import { Formik, Form } from "formik";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { axiosAuth as axios } from "../../utils/axios-instance";
import * as Yup from "yup";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import TextfieldWrapper from "../../components/FormsUI/TextFieldWrapper";
import ButtonWrapper from "../../components/FormsUI/ButtonWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Grid, Typography } from "@mui/material";
import { TextField } from "@mui/material";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 700,
    height: "auto",
    maxHeight: "90%",
    backgroundColor: "white",
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: "30px 10px",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  formField: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  parentDiv: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    padding: "10px 80px",
  },
  fixedInput: {
    padding: 8,
    color: "white",
    backgroundColor: "#4066B1",
    borderRadius: 3,
  },
}));

const FORM_VALIDATION = Yup.object().shape({
  //   firstName: Yup.string().required("Polje je obavezno"),
  //   lastName: Yup.string().required("Polje je obavezno"),
  //   email: Yup.string()
  //     .email("Neispravan unos")
  //     .required("Morate uneti email adresu"),
  password: Yup.string().min(5, "Minimum password length is 5"),
  repeatPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords do not match"
  ),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email adress")
    .required("Email is required"),
  company: Yup.string().required("Company is required"),
  country: Yup.string().required("Country is required"),
  phone: Yup.string().required("Phone number is required"),
});

const Edit = ({
  data,
  modalOpen,
  setModal,
  setRefresh,
  setSeverityHandler,
  setSnackbarHandler,
}) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  const editUser = async (values) => {
    try {
      // console.log({
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   email: values.email,
      //   password: values.password,
      //   role: values.role,
      //   company: values.company,
      //   country: values.country,
      //   phone: values.phone,
      // });
      setRefresh(true);
      const res = await axios.patch(`/api/admin/users/${data._id}`, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        role: values.role,
        company: values.company,
        country: values.country,
        phone: values.phone,
      });
      setModal(false);
      setSeverityHandler("success");
      setSnackbarHandler({
        open: true,
        message: res.data.message,
      });
      setRefresh(false);
    } catch (error) {
      setSeverityHandler("warning");
      setSnackbarHandler({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "User change failed.",
      });
    }
  };

  const roles = [
    { label: "Admin", value: "admin" },
    { label: "Creator", value: "creator" },
    { label: "User", value: "user" },
  ];

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography variant="h4" align="center">
        Edit user
      </Typography>
      <Formik
        validateOnChange={false}
        initialValues={{
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: "",
          repeatPassword: "",
          role: data.role,
          company: data.company,
          country: data.country,
          phone: data.phone,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => editUser(values)}
      >
        {({ setFieldValue, isSubmitting, errors, values }) => (
          <Form>
            <div className={classes.parentDiv}>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>First name</span>
                <TextfieldWrapper
                  name="firstName"
                  value={values.firstName}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
                {/* <div className={classes.fixedInput}>
                  {values.firstName ? values.firstName : "/"}
                </div> */}
              </div>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Last name</span>
                <TextfieldWrapper
                  name="lastName"
                  value={values.lastName}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
                {/* <div className={classes.fixedInput}>
                  {values.lastName ? values.lastName : "/"}
                </div> */}
              </div>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Email adress</span>
                <TextfieldWrapper
                  name="email"
                  value={values.email}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
                {/* <div className={classes.fixedInput}>
                  {values.email ? values.email : "/"}
                </div> */}
              </div>

              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Company</span>
                {/* <div className={classes.fixedInput}>
                  {values.company ? values.company : "/"}
                </div> */}
                <TextfieldWrapper
                  name="company"
                  value={values.company}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </div>

              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Country</span>
                {/* <div className={classes.fixedInput}>
                  {values.country ? values.country : "/"}
                </div> */}
                <TextfieldWrapper
                  name="country"
                  value={values.country}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </div>

              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Phone number</span>
                {/* <div className={classes.fixedInput}>
                  {values.phone ? values.phone : "/"}
                </div> */}
                <TextfieldWrapper
                  name="phone"
                  value={values.phone}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </div>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Password</span>
                <TextfieldWrapper
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </div>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Confirm password</span>
                <TextfieldWrapper
                  name="repeatPassword"
                  //   label="Потврда лозинке"
                  type="password"
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </div>

              <div className={classes.formField}>
                {/* <FormControl fullWidth>
                  <span className={classes.tableRowLabel}>Role</span>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.role}
                    onChange={(e) => {
                      setFieldValue("role", e.target.value);
                    }}
                    classes={{ root: classes.rootFirstSelect }}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={Math.floor(Math.random() * 1987987423431744)}
                        value={role.value}
                      >
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <span className={classes.tableRowLabel}>Role</span>
                  <TextField
                    id="standard-select-currency"
                    select
                    // label="Select"
                    value={values.role}
                    onChange={(e) => {
                      setFieldValue("role", e.target.value);
                    }}
                    inputProps={{
                      sx: {
                        p: 1.25,
                      },
                    }}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={Math.floor(Math.random() * 1987987423431744)}
                        value={role.value}
                      >
                        {role.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <ButtonWrapper
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : undefined
                    }
                    style={{
                      margin: "10px 0",
                      backgroundColor: "#4066B1",
                    }}
                  >
                    Edit
                  </ButtonWrapper>
                </Grid>

                <Grid item xs={6} className={classes.delBtnWrapper}>
                  <Button
                    variant="outlined"
                    fullWidth
                    color="error"
                    style={{
                      margin: "10px 0",
                    }}
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Edit;
