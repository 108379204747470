import React from "react";

import InputFactory from "../inputFactory/InputFactory";

import FilterGroup from "./FilterGroup";

const FilterGroups = ({ groups, setFilters, setReset, reset }) => {
  const wrapperNames = Object.keys(groups);

  const wrappers = wrapperNames.map((wrapper, index) => {
    if (groups[wrapper].length > 0 && groups[wrapper][0]?.category) {
      return (
        <FilterGroup
          isGrouped={true}
          setFilters={setFilters}
          setReset={setReset}
          reset={reset}
          wrapper={wrapper}
          groups={groups}
          key={index}
        />
      );
    }

    return (
      <React.Fragment key={index}>
        {groups[wrapper].map((el, idx) => (
          <InputFactory
            data={el}
            key={el.name}
            setFilters={setFilters}
            setReset={setReset}
            reset={reset}
          />
        ))}
      </React.Fragment>
    );
  });

  return <>{wrappers}</>;
};

export default FilterGroups;
