import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { axiosAuth as axios } from "../../utils/axios-instance";

import Typography from "@mui/material/Typography";

import TextfieldWrapper from "../../components/FormsUI/TextFieldWrapper";
import ButtonWrapper from "../../components/FormsUI/ButtonWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Grid, Autocomplete, TextField } from "@mui/material";
import SimpleModal from "../../components/Modal/Modal";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const FORM_VALIDATION = Yup.object().shape({});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    height: "auto",
    maxHeight: "90%",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "white",
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    padding: "30px 10px",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  formField: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  parentDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
  left: {
    flex: 1,
    padding: 20,
  },
  right: {
    flex: 1,
    padding: 20,
  },
  tags: {
    display: "flex",
    flexDirection: "column",
  },
  tagRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formDiv: {
    padding: 20,
  },
}));

const EditImage = ({
  image,
  modalOpen,
  setModal,
  setRefresh,
  setSeverityHandler,
  setSnackbarHandler,
}) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [filters, setFilters] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [initState, setInitState] = useState(false);

  const editImage = async (values) => {
    try {
      setRefresh(true);

      let tagNames = Object.keys(values).filter(
        (val) =>
          val !== "title" &&
          val !== "author" &&
          val !== "createdAt" &&
          val !== "creator" &&
          val !== "imageName" &&
          val !== "imgEtag" &&
          val !== "imgUrl" &&
          val !== "mymeType" &&
          val !== "tags" &&
          val !== "thumbEtag" &&
          val !== "thumbName" &&
          val !== "thumbUrl" &&
          val !== "updatedAt" &&
          val !== "_id"
      );
      let tagValuesArr = tagNames
        .filter((tag) => values[tag] !== "" && values[tag].length !== 0)
        .map((t) => values[t]);

      let spreadTags = [];
      tagValuesArr.forEach((tag) => tag.forEach((x) => spreadTags.push(x)));

      // let tagValuesString = tagValuesArr.join(" ");
      let obj = { tags: spreadTags };
      // eslint-disable-next-line
      Object.keys(values).map((key, index) => {
        obj[key] = values[key];
      });
      const res = await axios.patch(`/api/image/${image._id}`, obj);
      setModal(false);
      setSeverityHandler("success");
      setSnackbarHandler({
        open: true,
        message: res.data.message,
      });
      setRefresh(false);
    } catch (error) {
      console.log(error);
      setSeverityHandler("warning");
      setSnackbarHandler({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "Image edit failed.",
      });
    }
  };

  const getFilters = async () => {
    try {
      const response = await axios.get("/api/filter?limit=1000");
      setFilters(response.data.filters);
      let initial = { title: image.title, author: image.author };
      response.data.filters.map(
        (filter) =>
          (initial[filter.name.replaceAll(" ", "_")] = image[
            filter?.name?.replaceAll(" ", "_")
          ]
            ? image[filter?.name?.replaceAll(" ", "_")]
            : [])
      );
      setInitValues(initial);
      setInitState(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line
  }, []);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography variant="h4" align="center">
        Edit image
      </Typography>
      <Formik
        validateOnChange={false}
        initialValues={initValues}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => editImage(values)}
      >
        {({ setFieldValue, isSubmitting, errors, values }) => (
          <Form>
            <div className={classes.formDiv}>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Image title</span>
                <TextfieldWrapper name="title" value={values.title} />
              </div>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Author name</span>
                <TextfieldWrapper name="author" value={values.author} />
              </div>
              <div className={classes.formField}>
                <span className={classes.tableRowLabel}>Filters</span>
                {filters.map((filter, i) => (
                  // <FormControl
                  //   fullWidth
                  //   key={i + Math.floor(Math.random() * 19831712344)}
                  //   sx={{ margin: "20px 0" }}
                  // >
                  //   <InputLabel id="demo-simple-select-label">
                  //     {filter.name.replaceAll("_", " ")}
                  //   </InputLabel>
                  //   <Select
                  //     labelId="demo-simple-select-label"
                  //     id="demo-simple-select"
                  //     name={filter.name.replaceAll(" ", "_")}
                  //     value={values[filter.name.replaceAll(" ", "_")]}
                  //     label={filter.name.replaceAll("_", " ")}
                  //     onChange={(event) =>
                  //       setFieldValue(
                  //         filter.name.replaceAll(" ", "_"),
                  //         event.target.value
                  //       )
                  //     }
                  //   >
                  //     <MenuItem value="">
                  //       Choose a{" "}
                  //       {filter.name.toLowerCase().replaceAll("_", " ")}
                  //     </MenuItem>
                  //     {filter.values.map((value) => (
                  //       <MenuItem
                  //         key={i + Math.floor(Math.random() * 19831712344)}
                  //         value={value}
                  //       >
                  //         {value}
                  //       </MenuItem>
                  //     ))}
                  //   </Select>
                  // </FormControl>

                  <Autocomplete
                    multiple
                    key={Math.floor(Math.random() * 19831712344)}
                    disablePortal
                    id="combo-box-demo"
                    options={filter.values}
                    sx={{ width: "100%", mt: 2 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={filter.name.replaceAll("_", " ")}
                      />
                    )}
                    onChange={(event, value) =>
                      setFieldValue(filter.name.replaceAll(" ", "_"), value)
                    }
                    value={values[filter.name.replaceAll(" ", "_")]}
                    name={filter.name.replaceAll(" ", "_")}
                  />
                ))}
              </div>
              <Grid
                container
                spacing={2}
                alignItems="center"
                // className={classes.formField}
              >
                <Grid item xs={6}>
                  <ButtonWrapper
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : undefined
                    }
                    style={{
                      margin: "10px 0",
                      backgroundColor: "#4066B1",
                    }}
                  >
                    Edit
                  </ButtonWrapper>
                </Grid>
                <Grid item xs={6} className={classes.delBtnWrapper}>
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    style={{
                      margin: "10px 0",
                      padding: "6px 16px",
                    }}
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <div>
      {filters.length > 0 && initState ? (
        <Modal
          open={modalOpen}
          onClose={() => setModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      ) : (
        <SimpleModal />
      )}
    </div>
  );
};

export default EditImage;
