import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { axiosAuth as axios } from "../../utils/axios-instance";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

// import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";

import TextfieldWrapper from "../../components/FormsUI/TextFieldWrapper";
import ButtonWrapper from "../../components/FormsUI/ButtonWrapper";

import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import SimpleModal from "../../components/Modal/Modal";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    height: "auto",
    maxHeight: "90%",
    backgroundColor: "white",
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: "30px 10px",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  formField: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  parentDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
  left: {
    flex: 1,
    padding: 20,
  },
  right: {
    flex: 1,
    padding: 20,
  },
  tags: {
    display: "flex",
    flexDirection: "column",
  },
  tagRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    width: "100%",
  },
  uploadButton: {
    width: "unset",
    padding: "4px 27px",
    margin: "10px 0",
    borderRadius: "5px",
    boxShadow: "none",
    backgroundColor: "#1E8637",
    color: "white",
    "&:hover": {
      backgroundColor: "#1E8637",
    },
  },
}));

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  author: Yup.string().required("Author is required"),
});

const UploadImage = ({
  modalOpen,
  setModal,
  setRefresh,
  setSeverityHandler,
  setSnackbarHandler,
}) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [filters, setFilters] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [initState, setInitState] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const createImage = async (values) => {
    try {
      setRefresh(true);
      const fileData = new FormData();
      fileData.append("img", selectedImage);

      // Object.keys(values).map(
      //   (key) =>
      //     typeof values[key] === "object" &&
      //     values[key].length !== 0 &&
      //     console.log("obj", JSON.stringify(values[key]))
      //   // console.log("obj", values[key])
      //   // a[key] = JSON.stringify(values[key])
      //   // fileData.append(`${key}`, JSON.stringify(values[key]))
      // );

      Object.keys(values).map(
        (key) =>
          values[key] !== "" &&
          values[key].length !== 0 &&
          fileData.append(`${key}`, JSON.stringify(values[key]))
      );

      const res = await axios.post(
        `/api/image?title=${values.title}`,
        fileData
      );
      setModal(false);
      setSeverityHandler("success");
      setSnackbarHandler({
        open: true,
        message: res.data.message,
      });
      setRefresh(false);
    } catch (error) {
      console.log(error);
      setSeverityHandler("warning");
      setSnackbarHandler({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "Image upload failed.",
      });
    }
  };

  const getFilters = async () => {
    try {
      const response = await axios.get("/api/filter?limit=1000");
      setFilters(response.data.filters);
      let initial = { title: "", author: "" };
      response.data.filters.map((filter) => (initial[filter.name] = []));
      setInitValues(initial);
      setInitState(true);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImage = (event) => {
    try {
      setSelectedImage(event.target.files[0]);
    } catch (error) {
      setSeverityHandler("warning");
      setSnackbarHandler({
        open: true,
        message: error.response.data.message,
      });
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography variant="h4" align="center">
        Add image
      </Typography>
      <Formik
        validateOnChange={false}
        initialValues={initValues}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => createImage(values)}
      >
        {({ setFieldValue, isSubmitting, errors, values }) => (
          <Form>
            <div className={classes.parentDiv}>
              <div className={classes.left}>
                <div className={classes.formField}>
                  <span className={classes.tableRowLabel}>Image title</span>
                  <TextfieldWrapper name="title" />
                </div>
                <div className={classes.formField}>
                  <span className={classes.tableRowLabel}>Image author</span>
                  <TextfieldWrapper name="author" />
                </div>
                <div className={classes.formField}>
                  <label htmlFor="img">
                    <input
                      style={{ display: "none" }}
                      id="img"
                      name="img"
                      type="file"
                      // accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={uploadImage}
                    />
                    <Button
                      className={classes.uploadButton}
                      component="span"
                      endIcon={<AddIcon />}
                    >
                      Upload image
                    </Button>
                  </label>
                  {selectedImage && selectedImage.name}
                </div>

                <div className={classes.formField}>
                  <span className={classes.tableRowLabel}>Filters</span>
                  {filters.map((filter) => (
                    // <FormControl
                    //   fullWidth
                    //   key={Math.floor(Math.random() * 19831712344)}
                    //   sx={{ margin: "10px 0" }}
                    // >
                    //   <InputLabel id="demo-simple-select-label">
                    //     {filter.name.replaceAll("_", " ")}
                    //   </InputLabel>
                    //   <Select
                    //     labelId="demo-simple-select-label"
                    //     id="demo-simple-select"
                    //     name={filter.name}
                    //     value={values[filter.name] ? values[filter.name] : ""}
                    //     label={filter.name.replaceAll("_", " ")}
                    //     onChange={(event) =>
                    //       setFieldValue(filter.name, event.target.value)
                    //     }
                    //   >
                    //     <MenuItem value="">
                    //       Choose a{" "}
                    //       {filter.name.toLowerCase().replaceAll("_", " ")}
                    //     </MenuItem>
                    //     {filter.values.map((value) => (
                    //       <MenuItem
                    //         key={Math.floor(Math.random() * 19831712344)}
                    //         value={value}
                    //       >
                    //         {value}
                    //       </MenuItem>
                    //     ))}
                    //   </Select>
                    // </FormControl>

                    <Autocomplete
                      multiple
                      key={Math.floor(Math.random() * 19831712344)}
                      disablePortal
                      id="combo-box-demo"
                      options={filter.values}
                      sx={{ width: "100%", mt: 2 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={filter.name.replaceAll("_", " ")}
                        />
                      )}
                      onChange={(event, value) =>
                        setFieldValue(filter.name, value)
                      }
                      value={values[filter.name]}
                      name={filter.name}
                    />
                  ))}
                </div>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} className={classes.buttons}>
                    <ButtonWrapper
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting || !selectedImage}
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size="0.9rem" />
                        ) : undefined
                      }
                      style={{
                        margin: "10px 0",
                        backgroundColor: selectedImage ? "#4066B1" : "",
                      }}
                    >
                      Add
                    </ButtonWrapper>
                  </Grid>

                  <Grid item xs={6} className={classes.buttons}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="error"
                      style={{
                        margin: "10px 0",
                      }}
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <div>
      {initState ? (
        <Modal
          open={modalOpen}
          onClose={() => setModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      ) : (
        <SimpleModal />
      )}
    </div>
  );
};

export default UploadImage;
