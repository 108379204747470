import React, { useState } from "react";
import { Formik, Form } from "formik";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { axiosAuth as axios } from "../../utils/axios-instance";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import TextfieldWrapper from "../../components/FormsUI/TextFieldWrapper";
import CheckboxWrapper from "../../components/FormsUI/CheckboxWrapper";
import ButtonWrapper from "../../components/FormsUI/ButtonWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Grid } from "@mui/material";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  tags: Yup.array().of(Yup.string()),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    height: "auto",
    maxHeight: "90%",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "white",
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    padding: "30px 10px",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  formField: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  parentDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
  left: {
    flex: 1,
    padding: 20,
  },
  right: {
    flex: 1,
    padding: 20,
  },
  tags: {
    display: "flex",
    flexDirection: "column",
  },
  tagRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const categories = [
  "Destinations",
  "Tourist Offer",
  "Regions",
  "Time of Day",
  "Seasons",
  "Other",
  "Orientation/image format",
];

const EditFilter = ({
  filter,
  modalOpen,
  setModal,
  setRefresh,
  setSeverityHandler,
  setSnackbarHandler,
}) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [deleted, setDeleted] = useState([]);
  const [term, setTerm] = useState("");
  const [tagsArr, setTagsArr] = useState(filter.valuesArr);

  const editFilter = async (values) => {
    try {
      setRefresh(true);
      const res = await axios.patch(`/api/admin/filters/${filter._id}`, {
        name: values.name,
        type: values.type,
        multiple: values.multiple,
        values: tagsArr,
        toDelete: deleted,
        category: values.category,
      });
      setModal(false);
      setSeverityHandler("success");
      setSnackbarHandler({
        open: true,
        message: res.data.message,
      });
      setRefresh(false);
    } catch (error) {
      console.log(error);
      setSeverityHandler("warning");
      setSnackbarHandler({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "Filter change failed.",
      });
    }
  };

  const addTag = () => {
    setTagsArr([...tagsArr, term]);
    setTerm("");
  };

  const removeTag = (tag) => {
    setDeleted([...deleted, tag]);
    setTagsArr(tagsArr.filter((el) => el !== tag));
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography variant="h4" align="center">
        Edit filter
      </Typography>
      <Formik
        validateOnChange={false}
        initialValues={{
          name: filter.nameWithSpace,
          type: filter.type,
          tags: tagsArr,
          multiple: filter.multiple,
          category: filter.category,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => editFilter(values)}
      >
        {({ setFieldValue, isSubmitting, errors, values }) => (
          <Form>
            <div className={classes.parentDiv}>
              <div className={classes.left}>
                <div className={classes.formField}>
                  <span className={classes.tableRowLabel}>Category</span>
                  <FormControl
                    fullWidth
                    key={Math.floor(Math.random() * 19831712344)}
                  >
                    <Select
                      // labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={values.category}
                      // label="Category"
                      onChange={(event) =>
                        setFieldValue("category", event.target.value)
                      }
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={Math.floor(Math.random() * 19831712344)}
                          value={category}
                        >
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.formField}>
                  <span className={classes.tableRowLabel}>Filter name</span>
                  <TextfieldWrapper name="name" value={values.name} />
                </div>
                <FormControl component="fieldset" className={classes.formField}>
                  <FormLabel component="legend">Display</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    value={values.type}
                    name="type"
                    onChange={(event, value) => setFieldValue("type", value)}
                  >
                    <FormControlLabel
                      value="checkbox"
                      control={<Radio />}
                      label="Checkbox"
                    />
                    <FormControlLabel
                      value="select"
                      control={<Radio />}
                      label="Select"
                    />
                  </RadioGroup>
                </FormControl>
                <div className={classes.formField}>
                  <CheckboxWrapper
                    name="multiple"
                    label="Multiple"
                    currentValue={values.multiple}
                  />
                </div>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <ButtonWrapper
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size="0.9rem" />
                        ) : undefined
                      }
                      style={{
                        margin: "10px 0",
                        backgroundColor: "#4066B1",
                      }}
                    >
                      Edit
                    </ButtonWrapper>
                  </Grid>
                  <Grid item xs={6} className={classes.delBtnWrapper}>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      style={{
                        margin: "10px 0",
                        padding: "6px 16px",
                      }}
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.right}>
                <div className={classes.formField}>
                  <span className={classes.tableRowLabel}>Tag name</span>
                  <TextField
                    variant="outlined"
                    onChange={(event) => setTerm(event.target.value)}
                    value={term}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    style={{
                      margin: "10px 0",
                    }}
                    onClick={addTag}
                    disabled={term.length === 0}
                  >
                    Add tag
                  </Button>
                </div>
                <div className={classes.tags}>
                  {tagsArr.map((tag) => (
                    <div
                      className={classes.tagRow}
                      key={Math.floor(Math.random() * 1987987423431744)}
                    >
                      <span>{tag}</span>
                      <span onClick={() => removeTag(tag)}>
                        <DeleteIcon />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default EditFilter;
