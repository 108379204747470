import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "../components/Table/Table";
import Grid from "@mui/material/Grid";
import { axiosAuth as axios } from "../utils/axios-instance";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  deleteButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  tableDiv: {
    margin: "20px 20px 50px 20px",
  },
  title: {
    color: "#6a6767",
  },
  icon: {
    color: "#6a6767",
    marginLeft: 10,
  },
}));

const TableLinks = ({ data, setSeverity, setSnackbar, setRefresh }) => {
  const classes = useStyles();
  // console.log(data);
  const linkData = data.map((el) => ({
    ...el,
    url: (
      <Link to={{ pathname: el.url }} replace target="_blank">
        {el.url}
      </Link>
    ),
  }));
  const deleteLink = async (id) => {
    setRefresh(true);
    await axios.delete(`api/share/${id}`);
    setSeverity("success");
    setSnackbar({
      open: true,
      message: "Link deleted successfully.",
    });
    setRefresh(false);
  };

  const actionBodyTemplate = (rowData) => (
    <Grid key="edit" container spacing={2}>
      <Grid item xs={6}>
        <Button
          name="obrisi"
          className={classes.deleteButton}
          onClick={() => deleteLink(rowData.id)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </Grid>
    </Grid>
  );

  let userInfo = {};

  if (window.location.pathname === "/tos/links") {
    userInfo.field = "creator";
    userInfo.header = "CREATOR";
  }

  const columns = [
    { field: "url", header: "URL" },
    userInfo,
    {
      field: "date",
      header: "DATE",
    },
    { field: "edit", body: actionBodyTemplate, width: 200 },
  ];
  return (
    <div style={{ width: "100%" }}>
      <Table value={linkData} emptyMessage="No links" columns={columns} />
    </div>
  );
};

export default TableLinks;
