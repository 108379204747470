import React from "react";
import { Typography, Box } from "@mui/material";

const PageTitle = (props) => {
  return (
    <>
      <Typography
        variant="h4"
        mt={4}
        mb={1}
        align="center"
        color="#99001C"
        fontWeight="bold"
      >
        {props.children}
      </Typography>
      <Box
        sx={{
          width: 100,
          height: 5,
          backgroundColor: "#99001C",
          margin: "0 auto",
          mb: 2,
          borderRadius: 10,
        }}
      ></Box>
    </>
  );
};

export default PageTitle;
