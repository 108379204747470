import {
  Button,
  Grid,
  Typography,
  Box,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// import InputFactory from "../inputFactory/InputFactory";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@emotion/react";

import FilterGroups from "./FilterGroups";

const FilterSideBar = ({ data, setFilters, filters }) => {
  const [reset, setReset] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1000));
  const filtersReducer =
    data &&
    data.filters.reduce((acc, currentEl) => {
      return {
        ...acc,
        [currentEl.name]: [],
      };
    }, {});

  // group all filters by category or name
  const groupedFilters = data.filters.reduce(function (acc, currentEl) {
    acc[currentEl.category ? currentEl.category : currentEl.name] =
      acc[currentEl.category ? currentEl.category : currentEl.name] || [];
    acc[currentEl.category ? currentEl.category : currentEl.name].push(
      currentEl
    );
    return acc;
  }, Object.create(null));

  useEffect(() => {
    if (data.filters) setFilters(filtersReducer);
    // eslint-disable-next-line
  }, []);
  //reset filters
  const handleReset = () => {
    setFilters(filtersReducer);
    setReset(true);
  };

  //open filters
  const handleOpenFilters = (e) => {
    e.preventDefault();
    setOpenDrawer(!openDrawer);
  };

  const drawer = (
    <>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            minWidth: "200px",
            maxWidth: "300px",
          },
        }}
        sx={{ minWidth: 200 }}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <Grid
          item
          sx={{
            // borderRight: "2px solid #d1d1d1",
            // borderBottom: "1px solid #d1d1d1",
            minWidth: 150,

            //
          }}
        >
          <Typography
            variant="h4"
            color="#99001C"
            sx={{ mt: 4, textAlign: "center" }}
          >
            Filters
          </Typography>
          <Box
            sx={{
              width: 100,
              height: 5,
              backgroundColor: "#99001C",
              margin: "0 auto",
              mb: 2,
              borderRadius: 10,
            }}
          ></Box>
          <Grid
            container
            sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}
            spacing={1}
          >
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                sx={{ fontSize: "0.8rem", marginBottom: 3 }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              maxHeight: "73vh",
              maxWidth: "100%",
              overflowY: "auto",
            }}
          >
            {data && data.filters && groupedFilters && (
              <FilterGroups
                groups={groupedFilters}
                setFilters={setFilters}
                setReset={setReset}
                reset={reset}
              />
            )}
          </Grid>
        </Grid>
      </SwipeableDrawer>
      <Grid
        item
        onClick={handleOpenFilters}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          margin: 4,
          cursor: "pointer",
        }}
      >
        <FilterAltIcon
          sx={{
            height: "25px",
            width: "25px",
            color: "#99001C",
          }}
        />
        <Typography
          variant="h4"
          color="#99001C"
          sx={{ textAlign: "center", marginTop: "3px" }}
        >
          Filters
        </Typography>
      </Grid>
    </>
  );

  const fixedSideBar = (
    <Grid
      item
      md={2}
      lg={2}
      sx={{
        // borderRight: "2px solid #d1d1d1",
        // borderBottom: "1px solid #d1d1d1",
        minWidth: 150,

        //
      }}
    >
      <Typography
        variant="h4"
        color="#99001C"
        sx={{ mt: 4, textAlign: "center" }}
      >
        Filters
      </Typography>
      <Box
        sx={{
          width: 100,
          height: 5,
          backgroundColor: "#99001C",
          margin: "0 auto",
          mb: 2,
          borderRadius: 10,
        }}
      ></Box>
      <Grid
        container
        sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}
        spacing={1}
      >
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            sx={{ fontSize: "0.8rem", marginBottom: 3 }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          maxHeight: "73vh",
          maxWidth: "100%",
          overflowY: "auto",
        }}
      >
        {data && data.filters && groupedFilters && (
          <FilterGroups
            groups={groupedFilters}
            setFilters={setFilters}
            setReset={setReset}
            reset={reset}
          />
        )}
      </Grid>
    </Grid>
  );
  return <>{matches ? fixedSideBar : drawer}</>;
};

export default FilterSideBar;
