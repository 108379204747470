import { MY_PROFILE } from "../constants/userConstants";

export const myProfileReducer = (state = { user: "" }, action) => {
  switch (action.type) {
    case MY_PROFILE:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
