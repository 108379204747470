import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextfieldWrapper from "../TextFieldWrapper";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { axiosAuth as axios } from "../../../utils/axios-instance";
import { USER_FORM_VALIDATION } from "./validation";

const UserProfileForm = () => {
  const myInfo = useSelector((state) => state.myProfile.user);
  // const [erros, setErrors] = useState;

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const INITIAL_FORM_STATE = {
    oldpassword: "",
    password: "",
    repeatpassword: "",
  };

  const handleChange = async (values, { resetForm }) => {
    try {
      const url = `/api/user/change-password`;

      const userObj = {
        password: values.oldpassword,
        newPassword: values.password,
        newPassword2: values.repeatpassword,
      };
      const res = await axios.patch(url, userObj);
      setSeverity("success");
      setSnackbar({
        open: true,
        message: res.data.message,
      });

      resetForm(INITIAL_FORM_STATE);
    } catch (err) {
      console.log(err);
      setSeverity("warning");
      setSnackbar({
        open: true,
        message: "Password change failed",
      });
      // const error = err.response.data.message.includes('Korisnik')
      //     ? 'email'
      //     : 'lozinka'
      //   setErrors({
      //     [error]: err.response && err.response.data.message,
      //   });
    }
  };

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={USER_FORM_VALIDATION}
        onSubmit={handleChange}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{ p: 1, backgroundColor: "#4066B1", borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography color="white">Firstname</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="white">{myInfo.firstName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{ p: 1, backgroundColor: "#4066B1", borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography color="white">Lastname</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="white">{myInfo.lastName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{ p: 1, backgroundColor: "#4066B1", borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography color="white">Email</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="white">{myInfo.email}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{ p: 1, backgroundColor: "#4066B1", borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography color="white">Company</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="white">{myInfo.company}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{ p: 1, backgroundColor: "#4066B1", borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography color="white">Country</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="white">{myInfo.country}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{ p: 1, backgroundColor: "#4066B1", borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography color="white">Phone</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="white">{myInfo.phone}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextfieldWrapper
                  name="oldpassword"
                  type="password"
                  variant="outlined"
                  label="Old password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextfieldWrapper
                  name="password"
                  type="password"
                  variant="outlined"
                  label="Password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextfieldWrapper
                  name="repeatpassword"
                  type="password"
                  variant="outlined"
                  label="Repeat Password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#4066B1" }}
                >
                  submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserProfileForm;
