import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ImageSharing from "../pages/ImageSharing";
import NotFound from "../pages/NotFound";
import { Container } from "@mui/material";

function PublicRoutes() {
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/share/:id">
          <Container>
            <ImageSharing />
          </Container>
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
}

export default PublicRoutes;
