import React from 'react'
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import {Typography, Box} from "@mui/material/";

const ResultNotFound = () => {
    return (
        <Box sx={{textAlign: 'center'}}>
            <CircleNotificationsOutlinedIcon fontSize='large'/>
            <Typography>Search Results not found</Typography>
        </Box>
    )
}

export default ResultNotFound
