import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { axiosAuth as axios } from "../../utils/axios-instance";
import fileDownload from "js-file-download";
import CircularProgress from "@mui/material/CircularProgress";
import ModalImage from "react-modal-image";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   bgcolor: "#7f7878",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export default function MultiActionAreaCard({
  imgUrl,
  author,
  id,
  click,
  thumbUrl,
  shared,
  title,
  mimeType,
  imageName,
  selectIcon,
}) {
  const [checked, setchecked] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [downloading, setDownloading] = React.useState(false);

  const handleCheck = () => {
    click(id);
    setchecked((prevstate) => !prevstate);
  };

  const downloadImage = async (id, name, mimeType) => {
    setDownloading(true);
    const { data } = await axios.get(`/api/image/download-image/${id}`, {
      responseType: "blob",
    });
    setDownloading(false);
    return fileDownload(data, name, mimeType);
  };

  return (
    <Card sx={{ maxWidth: 200, minWidth: 150, position: "relative" }}>
      {shared ? null : (
        <CheckCircleIcon
          sx={{
            display: selectIcon ? "block" : "none",
            position: "absolute",
            top: 12,
            left: 10,
            zIndex: 10,
            color: checked ? "#5585F9" : "gray",
            cursor: "pointer",
          }}
          onClick={handleCheck}
        />
      )}

      {/* <CardMedia
          component="img"
          height="250"
          image={thumbUrl}
          alt="green iguana"
          id={id}
          onClick={handleOpen}
        /> */}
      <ModalImage
        small={thumbUrl}
        large={imgUrl}
        hideZoom={true}
        hideDownload={true}
      />
      <Typography
        variant="subtitle2"
        component="div"
        sx={{ overflow: "hidden", ml: 1 }}
      >
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ overflow: "hidden", ml: 1 }}
        >
          by {author}
        </Typography>

        <Button
          sx={{ color: "rgba(0, 0, 0, 0.87)", minWidth: 0 }}
          onClick={() => {
            downloadImage(id, imageName, mimeType);
          }}
          startIcon={
            downloading ? (
              <CircularProgress size="0.9rem" />
            ) : (
              <FileDownloadOutlinedIcon sx={{ ml: 2 }} />
            )
          }
        />
      </Box>

      {/* {open && <Lightbox large={imgUrl} onClose={handleClose} />} */}
    </Card>
  );
}
