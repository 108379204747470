import React from "react";
import { DataTable } from "primereact/datatable";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Column } from "primereact/column";
import Pagination from "@mui/material/Pagination";

const Table = ({
  value,
  emptyMessage,
  columns,
  page,
  rowsPerPage,
  setPage,
  // setRowsPerPage,
  withPagination,
  total,
}) => {
  let tableColumns = columns.map((column) => {
    if (column.field === "tagsColumn")
      return (
        <Column
          key={Math.floor(Math.random() * 1987987423431744)}
          body={column.body}
          exportable={false}
          style={{ width: column.width }}
          header={column.header}
        ></Column>
      );
    if (column.field === "edit")
      return (
        <Column
          key={Math.floor(Math.random() * 1987987423431744)}
          body={column.body}
          exportable={false}
          style={{ width: column.width }}
        ></Column>
      );
    return (
      <Column
        key={Math.floor(Math.random() * 1987987423431744)}
        field={column.field}
        header={column.header}
      ></Column>
    );
  });

  const pagination = (
    <div style={{ margin: "auto", width: "max-content" }}>
      <Pagination
        count={Math.ceil(total / rowsPerPage)}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </div>
  );

  return (
    <DataTable
      value={value}
      responsiveLayout="scroll"
      id="data-table"
      emptyMessage={emptyMessage}
      footer={withPagination && pagination}
    >
      {tableColumns}
    </DataTable>
  );
};

export default Table;
