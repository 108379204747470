import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemButton,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const CheckBoxContainer = ({
  data,
  setFilters,
  setReset,
  reset,
  isGrouped,
}) => {
  //open checkBoxes container
  const [open, setOpen] = useState(false);
  // const [checkAll, setCheckAll] = useState(false);
  //add boolean to every checkbox value, to check if its checked

  const checkBoxUnchecked = data.values.reduce((r, key) => {
    return {
      ...r,
      [key]: false,
    };
  }, {});
  const checkBoxAllChecked = data.values.reduce((r, key) => {
    return {
      ...r,
      [key]: true,
    };
  }, {});

  const [value, setValue] = useState(checkBoxUnchecked);

  //control checkBoxes
  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.checked });
    setReset(false);
  };
  // open checkBox container
  const handleOpen = () => {
    if (Object.keys(value).length !== 0) setOpen(!open);
  };

  // check if all checkboxes are selected or not
  const areAllChecked = () => {
    return Object.values(value).every((v) => v === true);
  };

  const areAllUnchecked = () => {
    return Object.values(value).every((v) => v === false);
  };

  // check|uncheck  all checkboxes
  const handleCheckAll = (event) => {
    if (areAllChecked()) {
      setValue(checkBoxUnchecked);
    }
    if (!areAllChecked()) {
      setValue(checkBoxAllChecked);
    }
  };

  // add array of checked checkboxes to All filters object
  useEffect(() => {
    const activeFilters = Object.keys(value).filter((key) => value[key]);
    setFilters((prev) => {
      return { ...prev, [data.name]: activeFilters };
    });
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (reset) setValue(checkBoxUnchecked);
    // eslint-disable-next-line
  }, [reset]);
  return (
    <Paper
      sx={{
        width: isGrouped ? "100%" : "90%",
        // margin: isLast ? "5px auto 20px auto" : "5px auto",
        margin: "5px auto",
        minWidth: 150,
      }}
    >
      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <ListItemButton onClick={handleOpen} sx={{ width: "100%" }}>
          <FormControlLabel
            label=""
            control={
              <Checkbox
                checked={areAllChecked() && Object.keys(value).length !== 0}
                indeterminate={!areAllChecked() && !areAllUnchecked()}
                onChange={handleCheckAll}
                color="secondary"
                disabled={Object.keys(value).length === 0}
              />
            }
          />
          {data.name.length > 20 ? (
            <Tooltip title={data.name.replaceAll("_", " ")}>
              <ListItemText
                primaryTypographyProps={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {data.name.replaceAll("_", " ")}
              </ListItemText>
            </Tooltip>
          ) : (
            <ListItemText
              primaryTypographyProps={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
              }}
            >
              {data.name.replaceAll("_", " ")}
            </ListItemText>
          )}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout={0} unmountOnExit>
          <FormGroup>
            {data.values.map((el) => (
              <FormControlLabel
                sx={{
                  pl: 3,
                }}
                key={el}
                control={
                  <Checkbox
                    checked={value[el]}
                    onChange={handleChange}
                    name={el}
                    size="small"
                    color="secondary"
                  />
                }
                label={
                  el.length > 20 ? (
                    <Tooltip title={el}>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {el}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {el}
                    </Typography>
                  )
                }
              />
            ))}
          </FormGroup>
        </Collapse>
      </FormControl>
    </Paper>
  );
};

export default CheckBoxContainer;
