import React, { useState, useEffect } from "react";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// import { Button } from "primereact/button";
import { axiosAuth as axios } from "../../utils/axios-instance";
import { Lightbox } from "react-modal-image";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";

import SimpleModal from "../../components/Modal/Modal";
import Table from "../../components/Table/Table";
import AddButton from "../../components/FormsUI/AddButton";
import UploadImage from "./UploadImage";
import EditImage from "./EditImage";
import DeleteImage from "./DeleteImage";
import PageTitle from "../../components/FormsUI/PageTitle";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  deleteButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  tableDiv: {
    margin: "20px 20px 50px 20px",
  },
  title: {
    color: "#6a6767",
  },
  icon: {
    color: "#6a6767",
    marginLeft: 10,
  },
}));

const Images = () => {
  const [addOpen, setAddOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [images, setImages] = useState([]);
  const [url, setUrl] = useState("");
  const [urlState, setUrlState] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const getImages = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `/api/image?skip=${page - 1}&limit=${rowsPerPage}`
      );

      // const tableData = response?.data?.images?.reduce((acc, currentEl) => {
      //   const addElement = {
      //     _id: currentEl._id,
      //     tags: currentEl.tags,
      //     tagsStr: currentEl.tags.join(", "),
      //     title: currentEl.title,
      //     author: currentEl.author,
      //   };
      //   acc.push(addElement);
      //   return acc;
      // }, []);

      setImages(response.data.images);
      setTotal(response.data.totalResults);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!refreshState) getImages();
    // eslint-disable-next-line
  }, [refreshState, page, rowsPerPage]);

  const setRefresh = (state) => {
    setRefreshState(state);
  };

  const setSeverityHandler = (state) => {
    setSeverity(state);
  };

  const setSnackbarHandler = (state) => {
    setSnackbar(state);
  };

  const addImage = () => {
    setAddOpen(true);
  };

  const editImage = (data) => {
    setEditOpen(true);
    setEditId(data);
  };

  const deleteImage = (data) => {
    setDeleteOpen(true);
    setDeleteId(data);
  };

  const handlePreview = (data) => {
    setUrl(data.imgUrl);
    setUrlState(true);
  };

  const handlePreviewClose = () => {
    setUrl("");
    setUrlState(false);
  };

  const setPageHandler = (value) => {
    setPage(value);
  };

  const tagsString = (rowData) => {
    return rowData.tags.join(", ");
  };

  const actionBodyTemplate = (rowData) => (
    <Grid key="edit" container spacing={2}>
      <Grid item xs={4}>
        <Button
          name="preview"
          className={classes.deleteButton}
          onClick={() => handlePreview(rowData)}
        >
          <VisibilityIcon className={classes.icon} />
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          name="izmeni"
          className={classes.editButton}
          onClick={() => editImage(rowData)}
        >
          <EditIcon className={classes.icon} />
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          name="obrisi"
          className={classes.deleteButton}
          onClick={() => deleteImage(rowData)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      field: "title",
      header: "TITLE",
    },
    {
      field: "tagsColumn",
      header: "TAGS",
      body: tagsString,
    },
    { field: "author", header: "AUTHOR" },
    { field: "edit", body: actionBodyTemplate, width: 300 },
  ];

  return (
    <div className={classes.tableDiv}>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {addOpen && (
        <UploadImage
          modalOpen={addOpen}
          setModal={setAddOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}
      {editOpen && editId && (
        <EditImage
          image={editId}
          modalOpen={editOpen}
          setModal={setEditOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}
      {deleteOpen && deleteId && (
        <DeleteImage
          image={deleteId}
          modalOpen={deleteOpen}
          setPage={setPage}
          setModal={setDeleteOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}
      {urlState && (
        <Lightbox hideDownload large={url} onClose={handlePreviewClose} />
      )}
      <PageTitle>Image Management</PageTitle>
      <AddButton click={addImage} label="ADD IMAGE" />
      <div>
        {load ? (
          <SimpleModal />
        ) : (
          <Table
            value={images}
            emptyMessage="No images"
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            withPagination={true}
            setPage={setPageHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Images;
