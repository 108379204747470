import { ADD_FILTERS, RESET_FILTERS } from "../constants/filterConstants";

export const filtersReducer = (state = { filters: [] }, action) => {
  switch (action.type) {
    case ADD_FILTERS:
        const items = action.payload
      return {
        filters: items.map(item => item)
      };
    case RESET_FILTERS:
      return {
        filters: [],
      };

    default:
      return state;
  }
};
