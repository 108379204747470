import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImgLogo from "../assets/turistickaLogo.png";
import PageTitle from "../components/FormsUI/PageTitle";
import { Button } from "@mui/material";
import ImageCard from "../components/cards/ImageCard";
import NoImg from "../assets/noimg.png";
import { axiosInstance as axios } from "../utils/axios-instance";
import CircularProgress from "@mui/material/CircularProgress";
import fileDownload from "js-file-download";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const ImageSharing = () => {
  const [sharedImg, setSharedImg] = useState([]);
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState("");
  const { id } = useParams();
  const [download, setDownload] = useState(false);

  // /api/image/zip/idshare-a

  const getSharedImages = async (imageId) => {
    setLoad(true);
    try {
      const { data } = await axios.get(`/api/share/${imageId}`);
      setSharedImg(data.share.images);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      setErr(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const downloadImage = async (id) => {
    setDownload(true);
    const { data } = await axios.get(`/api/image/zip/${id}`, {
      responseType: "arraybuffer",
    });
    setDownload(false);
    return fileDownload(data, `${id}.zip`);
  };

  useEffect(() => {
    getSharedImages(id);
  }, [id]);

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <img src={ImgLogo} width="200" alt="logo" />
      </Box>
      <PageTitle>NTOS Shared media</PageTitle>
      <Grid
        container
        justifyContent="center"
        sx={{ mt: 4, mb: 5, textAlign: "center" }}
      >
        <Grid item xs="6">
          <Typography variant="h5" sx={{ pb: 2 }}>
            Terms of use
          </Typography>
          <Typography variant="p" sx={{ fontSize: 12 }}>
            I hereby undertake to use the photographs I obtain from the Archives
            of the National Tourist Organisation of Serbia (NTOS) in their
            integral form for the purposes of promoting tourism of the Republic
            of Serbia, not to use for commercial purposes or give to third
            parties. Along with each photo, I will state the name of the author,
            if given, with the obligatory note that the photo belongs to the
            NTOS Archive.
          </Typography>
        </Grid>
      </Grid>

      {load ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : null}
      {err ? <Box sx={{ textAlign: "center" }}>LINK NOT FOUND</Box> : null}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {sharedImg?.map((item) => (
          <Box sx={{ p: 1 }} key={item._id}>
            <ImageCard
              imgUrl={item.imgUrl || NoImg}
              author={item.author}
              id={item._id}
              thumbUrl={item?.thumbUrl || NoImg}
              title={item.title}
              mymeType={item.mimeType}
              imageName={item.imageName}
              selectIcon={false}
            />
          </Box>
        ))}
      </Box>

      {sharedImg.length > 0 ? (
        <Box sx={{ textAlign: "center", mb: 3, mt: 3 }}>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => {
              downloadImage(id);
            }}
            disabled={err || download ? true : false}
            startIcon={
              !download ? (
                <FileDownloadOutlinedIcon sx={{ mr: 1, color: "white" }} />
              ) : (
                <CircularProgress size={25} sx={{ mr: 1, color: "white" }} />
              )
            }
          >
            Download zip
          </Button>{" "}
          {/* <Button variant="contained">Download images</Button> */}
        </Box>
      ) : (
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Link has expired
        </Typography>
      )}
    </>
  );
};

export default ImageSharing;
