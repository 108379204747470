import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClipboardCopy from "../FormsUI/ClipboardCopy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #2B57F4",
  boxShadow: 24,
  p: 4,
};

export default function CreateLinkModal({ close, linkString, isOpen }) {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Your share link:
          </Typography>
          <Box
            sx={{
              backgroundColor: "#ECECEC",
              borderRadius: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-description"
              sx={{
                p: 2,
                wordWrap: "break-word",
              }}
            >
              {linkString.url}
            </Typography>
            {/* <ContentCopyOutlinedIcon sx={{color: 'gray',mr: 2}} onClick={() => {navigator.clipboard.writeText(linkString.url)}}/> */}
            <ClipboardCopy text={linkString.url} />
          </Box>

          {/* <Typography id="modal-modal-description" sx={{ color: "#B77171" }}>
            Please save your share link before closing
          </Typography> */}
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button
              variant="outlined"
              onClick={close}
              sx={{ margin: "0 auto" }}
            >
              close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
