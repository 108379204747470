import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material";

const SelectContainer = ({ data, setFilters, setReset, reset, isGrouped }) => {
  const [value, setValue] = React.useState(data.multiple ? [] : null);
  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        [data.name]: value,
      };
    });
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (reset) setValue(data.multiple ? [] : null);
    // eslint-disable-next-line
  }, [reset]);

  return (
    <Paper
      sx={{
        width: isGrouped ? "100%" : "90%",
        // margin: isLast ? "7px auto 20px auto" : "7px auto",
        margin: "7px auto",
        minWidth: 150,
        display: "inline-block",
      }}
    >
      <Autocomplete
        value={value}
        multiple={data.multiple}
        id="tags-outlined"
        options={data.values}
        onChange={(event, newValue) => {
          setValue(newValue);
          setReset(false);
        }}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label={data.name} placeholder={data.name} />
        )}
      />
    </Paper>
  );
};

export default SelectContainer;
