import React, { useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
// import { copyTextToClipboard } from "../../utils/copyToClipboard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@mui/material";

const ClipboardCopy = ({ text }) => {
  const [open, setOpen] = useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  const handleTooltipOpen = () => {
    // copyTextToClipboard(text)
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  return (
    <>
      <div>
        <CopyToClipboard text={text} onCopy={handleTooltipOpen}>
          <ContentCopyOutlinedIcon
            sx={{
              color: "gray",
              mr: 2,
              cursor: "pointer",
              position: "relative",
            }}
          />
        </CopyToClipboard>
        {open ? (
          <Typography
            variant="p"
            sx={{
              position: "absolute",
              top: "100px",
              right: "10px",
              padding: "1px 5px",
              backgroundColor: "#6a6a6a",
              color: "white",
              borderRadius: 2,
            }}
          >
            copied!
          </Typography>
        ) : null}
      </div>
      {/* </ClickAwayListener> */}
    </>
  );
};

export default ClipboardCopy;
