import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import WarningIcon from "@mui/icons-material/Warning";
import { axiosAuth as axios } from "../../utils/axios-instance";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: 30,
    textAlign: "center",
  },
  warning: {
    color: "#AE0F0F",
  },
}));

export default function DeleteUser({
  data,
  modalOpen,
  setModal,
  setRefresh,
  setSeverityHandler,
  setSnackbarHandler,
  setPage,
}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const deleteUser = async () => {
    try {
      setRefresh(true);
      // setIsSubmitting(true);
      const res = await axios.delete(`/api/admin/users/${data._id}`);
      setModal(false);
      setSeverityHandler("success");
      setSnackbarHandler({
        open: true,
        message: res.data.message,
      });
      setPage(1);
      setRefresh(false);
      // setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setSeverity("warning");
      setSnackbar({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "Delete user failed.",
      });
    }
  };

  const body = (
    <>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div style={modalStyle} className={classes.paper}>
        <WarningIcon className={classes.warning} style={{ fontSize: 50 }} />
        <h3>Are you sure?</h3>
        <Button
          variant="contained"
          color="error"
          fullWidth
          disabled={isSubmitting}
          startIcon={
            isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
          }
          onClick={() => deleteUser()}
          style={{ margin: "10px 0" }}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={() => setModal(false)}
        >
          Cancel
        </Button>
      </div>
    </>
  );

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {data && body}
      </Modal>
    </div>
  );
}
