import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function SimpleModal() {
  const classes = useStyles();

  const body = <CircularProgress style={{ color: "white", outline: "none" }} />;

  return (
    <div>
      <Modal
        open={true}
        className={classes.paper}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
