import React from "react";
import { Route, Switch } from "react-router-dom";
import { SWRConfig } from "swr";
import Navbar from "../components/Navbar/Navbar";
import { axiosAuth } from "../utils/axios-instance";
import PrivateRoutes from "./PrivateRoutes";
// import Auth from './Auth'

function Routes() {
  return (
    <SWRConfig value={{ fetcher: (url) => axiosAuth(url).then((r) => r.data) }}>
      <Navbar />
      <Switch>
        <Route path="/tos">
          <PrivateRoutes />
        </Route>
      </Switch>
    </SWRConfig>
  );
}

export default Routes;
