import React, { useState, useEffect } from "react";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// import { Button } from "primereact/button";
import "./table.css";
import { axiosAuth as axios } from "../../utils/axios-instance";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import CreateFilter from "./CreateFilter";
import EditFilter from "./EditFilter";
import SimpleModal from "../../components/Modal/Modal";
import DeleteFilter from "./DeleteFilter";
import Table from "../../components/Table/Table";
import AddButton from "../../components/FormsUI/AddButton";
import PageTitle from "../../components/FormsUI/PageTitle";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  deleteButton: {
    backgroundColor: "transparent",
    color: "#6a6767",
    "&:hover": {
      backgroundColor: "transparent",
    },
    border: "none",
  },
  tableDiv: {
    margin: "20px 20px 50px 20px",
  },
  title: {
    color: "#6a6767",
  },
  icon: {
    color: "#6a6767",
    marginLeft: 10,
  },
}));

const Filters = () => {
  const [addOpen, setAddOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [filters, setFilters] = useState([]);
  const [refreshState, setRefreshState] = useState(false);
  const classes = useStyles();
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const getFilters = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `/api/filter?skip=${page - 1}&limit=${rowsPerPage}`
      );

      const tableData = response?.data?.filters?.reduce((acc, currentEl) => {
        const addElement = {
          _id: currentEl._id,
          valuesArr: currentEl.values,
          valuesStr: currentEl.values.join(", "),
          name: currentEl.name,
          nameWithSpace: currentEl.name.replaceAll("_", " "),
          type: currentEl.type,
          multiple: currentEl.multiple,
          category: currentEl.category ? currentEl.category : "",
        };
        acc.push(addElement);
        return acc;
      }, []);

      setFilters(tableData);

      setTotal(response.data.totalResults);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!refreshState) getFilters();
    // eslint-disable-next-line
  }, [refreshState, page]);

  const setRefresh = (state) => {
    setRefreshState(state);
  };

  const setSeverityHandler = (state) => {
    setSeverity(state);
  };

  const setSnackbarHandler = (state) => {
    setSnackbar(state);
  };

  const addFilter = () => {
    setAddOpen(true);
  };

  const editFilter = (data) => {
    setEditOpen(true);
    setEditId(data);
  };

  const deleteFilter = (data) => {
    setDeleteOpen(true);
    setDeleteId(data);
  };

  const setPageHandler = (value) => {
    setPage(value);
  };

  const actionBodyTemplate = (rowData) => (
    <Grid key="edit" container spacing={2}>
      <Grid item xs={6}>
        <Button
          name="izmeni"
          className={classes.editButton}
          onClick={() => editFilter(rowData)}
        >
          <EditIcon className={classes.icon} />
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          name="obrisi"
          className={classes.deleteButton}
          onClick={() => deleteFilter(rowData)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      field: "nameWithSpace",
      header: "TYPE",
    },
    {
      field: "category",
      header: "CATEGORY",
    },
    { field: "valuesStr", header: "TAGS" },
    { field: "edit", body: actionBodyTemplate, width: 200 },
  ];

  return (
    <div className={classes.tableDiv}>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {addOpen && (
        <CreateFilter
          modalOpen={addOpen}
          setModal={setAddOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}
      {editOpen && editId && (
        <EditFilter
          filter={editId}
          modalOpen={editOpen}
          setModal={setEditOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}

      {deleteOpen && deleteId && (
        <DeleteFilter
          filter={deleteId}
          setPage={setPage}
          modalOpen={deleteOpen}
          setModal={setDeleteOpen}
          setRefresh={setRefresh}
          setSeverityHandler={setSeverityHandler}
          setSnackbarHandler={setSnackbarHandler}
        />
      )}

      <PageTitle>Filter Management</PageTitle>
      <AddButton click={addFilter} label="ADD FILTER" />
      <div>
        {load ? (
          <SimpleModal />
        ) : (
          <Table
            value={filters}
            emptyMessage="No filters"
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPageHandler}
            total={total}
            withPagination={true}
          />
        )}
      </div>
    </div>
  );
};

export default Filters;
