import React, { useState, useEffect } from "react";
import PageTitle from "../../components/FormsUI/PageTitle";
import TableLinks from "../../components/TableLinks";
import SimpleModal from "../../components/Modal/Modal";
import { axiosAuth as axios } from "../../utils/axios-instance";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Links = () => {
  const [links, setLinks] = useState([]);
  const [loader, setLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [severity, setSeverity] = useState("info");

  const handleSnackBarOpen = () => {
    setSnackbar((previous) => ({
      ...previous,
      open: false,
    }));
  };

  const getLinks = async () => {
    setLoader(true);
    const res = await axios.get("/api/share/get/all");
    let linksArr = [...res.data];
    let arr = [];
    // eslint-disable-next-line
    linksArr.map((link) => {
      arr.push({
        id: link._id,
        date: link.dateCreated,
        url: link.url,
        creator: link.creator.firstName + " " + link.creator.lastName,
      });
    });
    setLinks(arr);
    setLoader(false);
  };

  useEffect(() => {
    if (!refresh) getLinks();
  }, [refresh]);

  return (
    <div>
      <Snackbar
        open={snackbar.open}
        onClose={handleSnackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        key="key"
      >
        <Alert onClose={handleSnackBarOpen} severity={severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <PageTitle>Links</PageTitle>
      {loader ? (
        <SimpleModal />
      ) : (
        <div style={{ width: "97.5%", margin: "10px auto" }}>
          <TableLinks
            data={links}
            setSeverity={setSeverity}
            setRefresh={setRefresh}
            setSnackbar={setSnackbar}
            withPagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default Links;
